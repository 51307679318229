<script>
export default {
    props: {
        
    },

    data() {
        return {

        };
    },

    created() {

    },

    methods: {

    }
};
</script>

<template>
    <a class="btn btn-orange mb-2" @click="$emit('click')">
        <i class="mdi mdi-plus mr-2"></i> Update
    </a>
</template>